import React, { Fragment } from 'react';
import 'foundation-sites/dist/css/foundation.min.css';
import { normalize } from 'polished';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import theme, { getColor } from './theme';
import * as routes from './routes';

import Navigation from './molecules/navigation/Navigation';
import SignUpPage from './pages/signUp/SignUp';
import SignInPage from './pages/signIn/SignIn';
import PasswordForgetPage from './molecules/passwordForget/PasswordForget';
import HomePage from './pages/home/HomeContainer';
import AccountPage from './pages/account/AccountContainer';
import withAuthentication from './hoc/withAuthentication';
import AdminPage from './pages/admin/AdminPageContainer';
import BoxTable from './molecules/BoxTable/BoxTableContainer';


const GlobalStyle = createGlobalStyle`
${normalize()};
`;

const App = () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <div>
        <GlobalStyle />
        <Navigation />
        <Switch>
          <Route
            exact
            path={routes.INDEX}
            component={SignInPage}
          />
          <Route
            exact
            path={routes.SIGN_IN}
            component={SignInPage}
          />
          <Route
            exact
            path={routes.PASSWORD_FORGET}
            component={PasswordForgetPage}
          />
          <Route
            exact
            path={routes.HOME}
            component={HomePage}
          />
          <Route
            exact
            path={routes.ACCOUNT}
            component={AccountPage}
          />
          <Route
            exact
            path={`${routes.BOX_TABLE}/:id`}
            component={BoxTable}
          />
        </Switch>
      </div>
    </BrowserRouter>
  </ThemeProvider>
);

export default withAuthentication(App);
