import { createSelector } from 'reselect';

const mountPoint = 'users';
const ACTION_PREPEND = `APP/${mountPoint.toUpperCase()}`;
const actionTypes = {};
export const actionCreators = {};
export const selectors = {};
const epics = {};

/*
* Initial State
*/
const initialState = {
  users: {},
};

/*
* Types
*/

actionTypes.USERS_SET = `${ACTION_PREPEND}/USERS_SET`;

/*
* Action Creators
*/

actionCreators.setUser = users => ({
  type: actionTypes.USERS_SET,
  payload: users,
});

/*
* Reducers
*/

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.USERS_SET:
      return {
        ...state,
        users: payload,
      };
    default: return state;
  }
};

/*
* Selectors
*/

selectors.usersSelector = state => state.users;

selectors.getUsersSelector = createSelector(
  selectors.usersSelector,
  status => status.users,
);

/*
*  Operations Async
*/

/*
* Interface
*/
const users = {
  mountPoint,
  epics,
  reducer,
};
export default users;
