import { db } from './firebase';

// User API

export const doCreateUser = (id, username, email) => db.collection('users').doc(id).set({
  username,
  email,
});

export const onceGetUsers = () => db.collection('users').get();
export const onceGetUser = uid => db.collection('users').doc(uid).get();
export const onceGetBox = type => db.collection('tableHome').where('type', '==', type).get();
export const updateBox = (type, data) => db.collection('tableHome').where('type', '==', type).set(data);
