import { compose } from 'recompose';
import { connect } from 'react-redux';
import { selectors } from '../../../store/modules/session';
import withAuthorization from '../../hoc/withAuthorization';
import AccountPage from './Account';

const mapStateToProps = state => ({
  authUser: selectors.authUserSelector(state),
});

const authCondition = authUser => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps),
)(AccountPage);
