import { createSelector } from 'reselect';

const mountPoint = 'session';
const ACTION_PREPEND = `APP/${mountPoint.toUpperCase()}`;
const actionTypes = {};
export const actionCreators = {};
export const selectors = {};
const epics = {};

/*
* Initial State
*/
const initialState = {
  authUser: null,
};

/*
* Types
*/

actionTypes.AUTH_USER_SET = `${ACTION_PREPEND}/AUTH_USER_SET`;

/*
* Action Creators
*/

actionCreators.setUserAuth = authUser => ({
  type: actionTypes.AUTH_USER_SET,
  payload: authUser,
});

/*
* Reducers
*/

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.AUTH_USER_SET:
      return {
        ...state,
        authUser: payload,
      };
    default: return state;
  }
};

/*
* Selectors
*/

selectors.sessionSelector = state => state.session;

selectors.authUserSelector = createSelector(
  selectors.sessionSelector,
  status => status.authUser,
);

/*
*  Operations Async
*/

/*
* Interface
*/
const session = {
  mountPoint,
  epics,
  reducer,
};
export default session;
