import { compose } from 'recompose';
import { connect } from 'react-redux';
import { selectors } from '../../../store/modules/session';
import withAuthorization from '../../hoc/withAuthorization';
import AdminPage from './AdminPage';

const mapStateToProps = state => ({
  authUser: selectors.authUserSelector(state),
});

const authCondition = authUser => !!authUser && authUser.role === 'ADMIN';

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps),
)(AdminPage);
