import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import SignOutButton from '../../atoms/SignOut';
import * as routes from '../../routes';
import { selectors } from '../../../store/modules/session';

const Navigation = ({ authUser }) => (
  <div className="top-bar">
    <div
      className="grid-container"
      style={{ width: '100%' }}
    >
      { authUser
        ? <NavigationAuth />
        : <NavigationNonAuth />
        }
    </div>
  </div>
);
const NavigationAuth = () => (
  <ul className="menu">
    <li><Link to={routes.HOME}>Administrador</Link></li>
    <li><Link to={routes.ACCOUNT}>Cuenta</Link></li>
    <li><SignOutButton /></li>
  </ul>
);
const NavigationNonAuth = () => (
  null
);
const mapStateToProps = state => ({
  authUser: selectors.authUserSelector(state),
});

export default connect(mapStateToProps)(Navigation);
