import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import ConfigureStore from './store/configureStore';

const { store, persistor } = ConfigureStore();

ReactDOM.render(
  <StoreProvider store={store}>
    <PersistGate
      persistor={persistor}
      loading={null}
    >
      <App />
    </PersistGate>
  </StoreProvider>,
  document.getElementById('root'),
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
