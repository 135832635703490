import React from 'react';
import { PasswordForgetForm } from '../../molecules/passwordForget/PasswordForget';
import PasswordChangeForm from '../../molecules/passwordChange/PasswordChange';

const AccountPage = ({ authUser }) => (
  <div className="grid-container">
    <h1>Cuenta</h1>
    <h4>Mediante correo electrónico</h4>
    <PasswordForgetForm />
    <h4>Cambiar contraseña</h4>
    <PasswordChangeForm />
  </div>
);

export default AccountPage;
