import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { db } from '../../../services/firebase';
import { BoxWireframe } from './HomeStyle';
import * as routes from '../../routes';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null,
      user: null,
    };
  }

  componentDidMount() {
    const { onSetUsers, authUser } = this.props;
    db.onceGetUsers()
      .then((querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
        onSetUsers(users);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
    // db.onceGetUser(authUser.uid).then(snapshot => this.setState({ user: snapshot.val() }));
    db.onceGetUser(authUser.uid).then((doc) => {
      if (doc.exists) {
        this.setState({ user: doc.data() });
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
    }).catch((error) => {
      console.log('Error getting document:', error);
    });
  }

  render() {
    const { users } = this.props;
    const { user } = this.state;

    return (
      <div className="grid-container">
        <h1>Noticias</h1>
        <div className="grid-x grid-padding-x grid-padding-y">
          <div
            className="cell small-8"
          >
            <div className="grid-x grid-padding-x grid-padding-y">
              <div className="cell">
                <Link to={`${routes.BOX_TABLE}/box1`}>
                  <div className="grid-x">
                    <div className="cell small-6">
                      <BoxWireframe height="300px" />
                    </div>
                    <div className="cell small-6">
                      <BoxWireframe
                        height="300px"
                        image
                      />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="cell">
                <Link to={`${routes.BOX_TABLE}/box3`}>
                  <div className="grid-x">
                    <div className="cell small-6">
                      <BoxWireframe
                        height="300px"
                        image
                      />
                    </div>
                    <div className="cell small-6">
                      <BoxWireframe
                        height="300px"
                      />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="cell small-4"
          >
            <Link to={`${routes.BOX_TABLE}/box2`}>
              <BoxWireframe
                height="50%"
                image
              />
              <BoxWireframe height="50%" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const UserList = ({ users }) => (
  <div>
    <h2>List of Usernames of Users</h2>
    <p>(Saved on Sign Up in Firebase Database)</p>

    {Object.keys(users).map(key => <div key={key}>{users[key].username}</div>)}
  </div>
);

export default HomePage;
