import { path } from 'ramda';

// ['640px', '1024px', '1200px', '1440px'];
export const breakpoints = ['40em', '64em', '75em', '90em'];

const basicColors = {
  primary: '#35919A',
  secondary: '#57BDB0',
  tertiary: '#E3E2C1',
  black: '#212121',
  white: '#fff',
  grayLight: '#E0E0E0',
  grayMedium: '#9E9E9E',
  grayDark: '#616161',
};

const fonts = {
  sans: 'Nunito, system-ui, sans-serif',
  mono: 'Menlo, monospace',
};

// Sizes fonts Global
// [ Paragraph XS, Paragraph S, Paragraph M, Paragraph/Header 6, Header 5, Header 4, Header 3, Header 2, Header1]
const fontSizes = [10, 12, 14, 16, 20, 25, 31, 40, 48];

const fontWeights = {
  thin: 200,
  light: 300,
  normal: 400,
  bold: 700,
  black: 900,
};

const heading = {
  lineHeight: 1,
};

const text = {
  fontFamily: 'Nunito, system-ui, sans-serif',
};

const theme = {
  colors: {
    ...basicColors,
  },
  fonts: {
    ...fonts,
  },
  fontSizes: [
    ...fontSizes,
  ],
  breakpoints: [
    ...breakpoints,
  ],
  fontWeights: {
    ...fontWeights,
  },
  Heading: {
    ...heading,
  },
  Text: {
    ...text,
  },
  breadCrumbHeight: '75px',
};

export const getColor = color => path(['theme', 'colors', color]);
export const getBreadCrumbHeight = path(['theme', 'breadCrumbHeight']);
export const getTextWeight = weight => path(['theme', 'fontWeights', weight]);

export default theme;
