import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { createEpicMiddleware } from 'redux-observable';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

import { rootEpic, rootReducer } from './store';

const persistConfig = {
  key: 'lahuellavegana',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const epicMiddleware = createEpicMiddleware();

const composeEnhancers = composeWithDevTools({ name: 'VOSS', trace: true, traceLimit: 25 });

const ConfigureStore = () => {
  const store = createStore(
    persistedReducer,
    // rootReducer, // Without persist
    composeEnhancers(
      applyMiddleware(epicMiddleware),
    ),
  );
  epicMiddleware.run(rootEpic);
  const persistor = persistStore(store);
  return {
    store,
    persistor,
  };
};

export default ConfigureStore;
