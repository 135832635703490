import styled from 'styled-components';
import image from '../../resources/picture.png';

export const BoxWireframe = styled('div')`
height: ${props => props.height};
position: relative;
background-color: ${props => (props.image ? 'gray' : 'lightgray')};
background-image: ${props => (props.image ? `url("${image}")` : null)};
background-size: contain;
background-repeat: no-repeat;
background-position: center;
`;
