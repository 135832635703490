import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { auth } from '../../../services/firebase';
import * as routes from '../../routes';

const SignInPage = ({ history }) => (
  <div className="grid-container">
    <div
      className="grid-x align-center-middle"
    >
      <div
        className="cell"
        style={{ maxWidth: '500px' }}
      >

        <h1>Iniciar sesión</h1>
        <SignInForm history={history} />
        <p>
          <Link to={routes.PASSWORD_FORGET}>¿Contraseña olvidada?</Link>
        </p>
      </div>
    </div>
  </div>
);
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

    onSubmit = (event) => {
      const {
        email,
        password,
      } = this.state;

      const {
        history,
      } = this.props;

      auth.doSignInWithEmailAndPassword(email, password)
        .then(() => {
          this.setState({ ...INITIAL_STATE });
          history.push(routes.HOME);
        })
        .catch((error) => {
          this.setState(byPropKey('error', error));
        });

      event.preventDefault();
    };

    render() {
      const {
        email,
        password,
        error,
      } = this.state;

      const isInvalid = password === ''
            || email === '';

      return (
        <form onSubmit={this.onSubmit}>
          <input
            value={email}
            onChange={event => this.setState(byPropKey('email', event.target.value))}
            type="text"
            placeholder="Email Address"
          />
          <input
            value={password}
            onChange={event => this.setState(byPropKey('password', event.target.value))}
            type="password"
            placeholder="Password"
          />
          <button
            disabled={isInvalid}
            className="button"
            type="submit"
          >
            {'Iniciar'}
          </button>

          { error && <p>{error.message}</p> }
        </form>
      );
    }
}

export default withRouter(SignInPage);

export {
  SignInForm,
};
