import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';


const prodConfig = {
  apiKey: 'AIzaSyBJPvrfjC-XmdKyCCibcFxxdDg9beYVokU',
  authDomain: 'la-huella-vegana.firebaseapp.com',
  databaseURL: 'https://la-huella-vegana.firebaseio.com',
  projectId: 'la-huella-vegana',
  storageBucket: 'la-huella-vegana.appspot.com',
  messagingSenderId: '101102462551',
};

const devConfig = {
  apiKey: 'AIzaSyBJPvrfjC-XmdKyCCibcFxxdDg9beYVokU',
  authDomain: 'la-huella-vegana.firebaseapp.com',
  databaseURL: 'https://la-huella-vegana.firebaseio.com',
  projectId: 'la-huella-vegana',
  storageBucket: 'la-huella-vegana.appspot.com',
  messagingSenderId: '101102462551',
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.firestore();
const settings = { timestampsInSnapshots: true };
db.settings(settings);
const auth = firebase.auth();
const storage = firebase.storage();

export {
  db,
  auth,
  storage,
};
