import React from 'react';

const AdminPage = ({ authUser }) => (
  <div>
    <h1>Admin</h1>
    <p>Restricted area! Only users with the admin rule are authorized.</p>
    <p>{authUser.email}</p>
  </div>
);

export default AdminPage;
