import React from 'react';
import { connect } from 'react-redux';

import { auth } from '../../services/firebase';
import { actionCreators } from '../../store/modules/session';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      const { onSetAuthUser } = this.props;

      auth.onAuthStateChanged(
        (authUser) => {
          authUser
            ? onSetAuthUser(authUser)
            : onSetAuthUser(null);
        },
      );
    }

    render() {
      return (
        <Component {...this.props} />
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: authUser => dispatch(actionCreators.setUserAuth(authUser)),
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
