import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import * as modules from './modules';

const mapModulesToReducers = (modulesBundle) => {
  const reducers = Object.keys(modulesBundle).map((key) => {
    const module = modulesBundle[key];
    return {
      [module.mountPoint]: module.reducer,
    };
  });
  return Object.assign({}, ...reducers);
};
export const rootReducer = combineReducers(mapModulesToReducers(modules));

const mapModulesToEpics = (modulesBundle) => {
  const epicsList = Object.keys(modulesBundle).map(key => Object.values(modulesBundle[key].epics));
  return epicsList.flat();
};

export const rootEpic = combineEpics(...mapModulesToEpics(modules));
