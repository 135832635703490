import { compose } from 'recompose';
import { connect } from 'react-redux';
import { actionCreators, selectors } from '../../../store/modules/users';
import { selectors as selectorsSession } from '../../../store/modules/session';
import withAuthorization from '../../hoc/withAuthorization';
import HomePage from './Home';

const mapStateToProps = state => ({
  users: selectors.getUsersSelector(state),
  authUser: selectorsSession.authUserSelector(state),
});

const mapDispatchToProps = dispatch => ({
  onSetUsers: users => dispatch(actionCreators.setUser(users)),
});

const authCondition = authUser => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
)(HomePage);
