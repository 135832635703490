import React from 'react';

import { auth } from '../../services/firebase';

const SignOutButton = () => (
  <button
    className="button"
    type="button"
    onClick={auth.doSignOut}
  >
    {'Salir'}
  </button>
);
export default SignOutButton;
