import React, { Component, Fragment } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { db, storage } from '../../../services/firebase';

const TITLE_MAX_LENGHT = 45;
const TEXT_MAX_LENGHT = 170;

const boxSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required')
    .max(TITLE_MAX_LENGHT, `Título superior a ${TITLE_MAX_LENGHT} carácteres`),
  text: Yup.string()
    .required('Required')
    .max(TEXT_MAX_LENGHT, `Texto superior a ${TEXT_MAX_LENGHT} carácteres`),
  textButton: Yup.string()
    .required('Required'),
  link: Yup.string()
    .required('Required'),
});


class BoxTable extends Component {
  constructor(props) {
    super(props);
    const { match: { params: { id: name } } } = this.props;
    this.state = {
      load: false,
      box: null,
      name,
      success: null,
    };
  }

  componentDidMount() {
    const { name } = this.state;
    db.onceGetBox(name).then((querySnapshot) => {
      const box = querySnapshot.docs[0].data();
      // FIXME
      const { photo, ...data } = box;
      this.setState({ box: data, load: true });
      return box.photo;
    })
      .then(photoURL => fetch(photoURL, {
        mode: 'no-cors',
      }))
      .then(res => res.blob())
      .then((blob) => {
        console.log(blob);
        // this.setState((state, props) => ({ load: true, box: { ...state.box, photo: blob } }));
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }

  handleSubmit = (data) => {
    const { name } = this.state;
    this.setState({ success: false });
    if (data.photo) {
      let newData = null;
      storage.uploadFile(data.photo, 'images', `${name}.jpg`)
        .then(snapshot => snapshot.ref.getDownloadURL())
        .then((downloadURL) => {
          newData = { ...data, photo: downloadURL };
          return db.onceGetBox(name);
        })
        .then((querySnapshot) => {
          const box = querySnapshot.docs[0];
          box.ref.set(newData, { merge: true });
          this.setState({ success: true });
        })
        .catch((error) => {
          console.log('Error getting document:', error);
        });
    } else {
      db.onceGetBox(name)
        .then((querySnapshot) => {
          const box = querySnapshot.docs[0];
          box.ref.set(data, { merge: true });
          this.setState({ success: true });
        })
        .catch((error) => {
          console.log('Error getting document:', error);
        });
    }
  };

  render() {
    const { box, load, success } = this.state;
    return (
      <Fragment>
        {
          load && box && (
            <div className="grid-container">
              <h1>Datos de la noticia</h1>
              <div>
                <Formik
                  initialValues={{ ...box }}
                  validationSchema={boxSchema}
                  isInitialValid
                  onSubmit={(values, actions) => {
                    this.handleSubmit(values);
                    actions.setSubmitting(false);
                  }}
                  render={props => (
                    <form onSubmit={props.handleSubmit}>
                      <p>Título:</p>
                      <Field
                        type="text"
                        name="title"
                        placeholder="Escribe un título"
                        maxLength={TITLE_MAX_LENGHT}
                      />
                      <ErrorMessage name="title">
                        {msg => <div style={{ color: 'red', marginBottom: '1rem' }}>{msg}</div>}
                      </ErrorMessage>
                      <p>Texto:</p>
                      <Field
                        component="textarea"
                        name="text"
                        placeholder="Escribe una descripción"
                        style={{ marginBottom: '1rem' }}
                        maxLength={TEXT_MAX_LENGHT}
                      />
                      <ErrorMessage name="text">
                        {msg => <div style={{ color: 'red', marginBottom: '1rem' }}>{msg}</div>}
                      </ErrorMessage>
                      <p>Texto del botón:</p>
                      <Field
                        type="text"
                        name="textButton"
                        placeholder="Escribe un nombre para el botón"
                      />
                      <ErrorMessage name="textButton">
                        {msg => <div style={{ color: 'red', marginBottom: '1rem' }}>{msg}</div>}
                      </ErrorMessage>
                      <p>Enlace:</p>
                      <Field
                        type="text"
                        name="link"
                        placeholder="Escribe un enlace"
                      />
                      <ErrorMessage name="link">
                        {msg => <div style={{ color: 'red', marginBottom: '1rem' }}>{msg}</div>}
                      </ErrorMessage>
                      <input
                        name="photo"
                        type="file"
                        onChange={(event) => {
                          props.setFieldValue('photo', event.currentTarget.files[0]);
                        }}
                        className="form-control"
                      />
                      <button
                        disabled={props.isSubmitting || !props.isValid}
                        className="button"
                        type="submit"
                      >
                        {'Guardar'}
                      </button>
                      { success && (
                        <div className="callout success">
                          <h5>¡Información subida!</h5>
                        </div>
                      ) }
                    </form>
                  )}
                />
              </div>
            </div>
          )
        }
      </Fragment>
    );
  }
}

export default BoxTable;
